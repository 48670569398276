<template>
    <div>
        <ul class="status_ul flex">
            <li :class="order_status.id === item.id ? 'active' : ''" v-for="(item, index) in statusList" :key="index"
                @click="statusTab(item)">
                {{ item.title }}
                <span class="status_num" v-if="item.id !== '' && item.id != 2 && item.num">{{
                item.num
            }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { getOrderNum } from '@/util/orderNum'
export default {
    name: '',
    created() { },
    mounted() {
        this.statusList = JSON.parse(JSON.stringify(this.$props.status_list))
        console.log(this.order_status);
        this.getNum()
    },
    props: {
        status_list:{
            type:[Array],
            default:''
        },
        order_status: Object,
        type: String,
        category_id: {
            type: [Number],
            default: ''
        }
    },
    data() {
        return {
            statusList:[],
        }
    },
    methods: {
        getNum() {
            if (this.category_id) {
                getOrderNum(this.statusList, this.type, this.category_id)

            } else {
                getOrderNum(this.statusList, this.type)
            }
            console.log(this.statusList);
        },
        statusTab(item) {
            
            this.getNum()
            // this.status = item
            this.$emit('statusTab', item)

        },
    },
}
</script>

<style lang=scss scoped></style>