<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="套餐订单列表" />
			<div class="ct_box">
				<div class="search-status_box flex">
					<div class="search-status_box flex">
						<div class="search_box flex">
							<el-date-picker value-format="timestamp" @change="timerFn" v-model="time" type="daterange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
							<div class="order_search flex">
								<img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
								<el-input class="order_search_inp" v-model="orderId" placeholder="输入标题/订单号"></el-input>
								<button class="search_but" @click="getList">搜索</button>
							</div>
						</div>


					</div>
					<orderStatus :category_id="0" :status_list="status_list" type="package_status_num"
						@statusTab="statusTab" :order_status="packOrder.order_status" />
				</div>
				<table class="package_table">
					<thead>
						<tr>
							<td>套餐名称</td>
							<td>订单号</td>
							<td>标题</td>
							<td>金额</td>
							<td>状态</td>
							<td>创建时间</td>
							<td>撤稿原因</td>
							<td style="width: 190px">操作</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="item.id">
							<td>{{ item.medium_title }}</td>
							<td>
								{{ item.sn }}
							</td>
							<td>
								<td_remark :text="item.title" />
								<span class="look" @click="lookShow(item.id)">[查看]</span>
							</td>
							<td>￥{{ item.medium_price }}</td>
							<td :style="{
		color: item.order_status == 0 ? '#000' : item.order_status == 1 ? '#FF8400' : item.order_status == 2 ?
			'#00B052' : item.order_status == 3 ? '#00B052' : '#FF0000'
	}">{{
		item.order_status == 0 ? '未处理' : item.order_status == 1 ? '发布中' : item.order_status == 2
			?
			'已出稿' : item.order_status == 3 ? '申请退款' : item.order_status == 4 ? '拒绝退款' : '撤稿'
	}}</td>
							<td>{{ item.ctime }}</td>
							<td>
								<td_remark :text="item.reason.id" />
								<td_remark :text="item.reason.season" />
							</td>
							<td class="operation3">
								<!-- ' -->
								<span v-if="item.order_status == 2" @click="LinkShow(item)">查看链接</span>
								<span
									v-if="(item.order_status == 0 && item.is_limit) || (item.order_status == 1 && item.is_limit) || item.order_status == 0"
									@click="withdraw(item)">撤稿</span>
								<!-- <span v-if="item.order_status == 0" @click="orderStatus(item, 5)">撤稿</span> -->

								<span
									v-if="(item.order_status == 1 && !item.is_limit) || (item.order_status == 2 && !item.is_limit)"
									@click="orderStatus(item, 3)">申请退款</span>

								<!-- <span @click="redispatchFn(item)" v-if="item.order_status == 2">再发布</span> -->
								<span @click="orderShow(item)" v-if="item.order_status == 2">提交工单</span>
								<span v-if="item.order_status == 4 || item.order_status == 9 || item.order_status == 7"
									@click="serviceShow = true">联系客服</span>
								<span @click="Over(item)"
									v-if="item.order_status == 3 || item.order_status == 4">取消退款</span>
								<span v-if="item.order_status == 0 || item.order_status == 5"
									@click="goEdit(item)">修改稿件</span>
								<span @click="issue(item)"
									v-if="item.order_status == 2 || item.order_status == 5 || item.order_status == 8">重新发布</span>

							</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :total_order_price="total_order_price" :count="count"
					:total_page="total_page" />
			</div>
		</div>
		<!-- 查看链接 -->
		<el-dialog title="查看链接" :append-to-body="true" :visible.sync="Link" :show-close="false">
			<table class="table2">
				<thead>
					<tr>
						<td>标题</td>
						<td>媒介</td>
						<td>链接</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{ linkList.title }}</td>
						<td>{{ linkList.medium_title }}</td>
						<td style="width: 25rem;">https://www.qixuantong.com{{ linkList.order_url }}</td>
					</tr>
				</tbody>
			</table>
			<div class="flex" style="margin-top: 2rem; justify-content: flex-end">
				<button class="copy_but" @click="copy">复制链接</button>
			</div>
		</el-dialog>
		<el-dialog title="提交工单" :append-to-body="true" :visible.sync="order" :show-close="false">
			<p class="workOrder_title">请描述您的问题</p>
			<el-input type="textarea" :rows="6" resize="none" v-model="work">
			</el-input>
			<div class="flex" style="margin-top: 2rem; justify-content: flex-end">
				<button class="copy_but" @click="orderUp">确认提交</button>
			</div>
		</el-dialog>
		<el-dialog title="联系客服" :append-to-body="true" :visible.sync="serviceShow" :show-close="false">
			<p class="workOrder_title">客服电话：83730126/82821214</p>

		</el-dialog>
		<rejectionPoup :himt="popupObj.himt" :order_status="order_status" :placeholder="popupObj.placeholder"
			:reason_title="popupObj.title" :title="popupObj.title" :rejectShow="rejectShow" :rejectItem="rejectItem"
			@rejectComfirm="rejectComfirm" @rejectClose="rejectShow = false" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Title from '@/components/UserInfoTitle.vue'
import rejectionPoup from '@/components/rejectionPoup.vue'
import orderStatus from '@/components/orderStatus.vue'
export default {
	components: {
		Title,
		rejectionPoup,
		orderStatus
	},
	computed: {
	},
	created() {
		this.$store.commit('childTab', '套餐订单')
		if (this.$route.query.time) {
			this.packOrder.orderStatus = {
				id: 6,
				title: '限时订单',
			}
		}
		this.getList()
	},
	computed: {
		...mapState(['packOrder'])
	},

	data() {
		return {
			time: '',
			orderId: '',
			tableData: [],
			Link: false, //查看链接弹窗
			order: false, //提交工单弹窗
			orderItem: {}, //工单信息
			serviceShow: false, //客服
			work: '',
			total_page: 0, //分页总页数
			total_order_price: 0, //条数总和
			count: 0, //总条数
			page: 1,
			linkList: {},
			fullscreenLoading: false, //loding
			rejectShow: false,
			order_status: 0,
			rejectItem: {},
			popupObj: {
				himt: '',
				title: '',
				reason_title: '',
				placeholder: '',

			},
			status_list: [
				{
					id: '',
					title: '全部',
				},
				{
					id: 0,
					title: '未处理',
				},
				{
					id: 1,
					title: '发布中',
				},
				{
					id: 2,
					title: '已出稿',
				},
				{
					id: 3,
					title: '申请退款',
				},
				{
					id: 4,
					title: '拒绝退款',
				},
				{
					id: 5,
					title: '撤稿',
				},
				{
					id: 6,
					title: '限时订单',
				},
			],
		}
	},
	methods: {
		statusTab(item) {
			console.log('statusItem:', item);
			this.packOrder.order_status = {
				id: item.id,
				title: item.title
			}
			console.log('packStatus:', this.packOrder);
			this.page = this.pageChangeOne()
			this.getList()

		},
		pageChange(pageVal) {
			this.page = pageVal
			this.getList()
		},

		LinkShow(item) {
			this.linkList = {
				title: item.title,
				order_url: item.order_url,
				medium_title: item.medium_title,
			}
			this.Link = true
		},
		rejectComfirm() {
			this.rejectShow = false
			this.getList()
		},
		orderShow(item) {
			this.order = true
			this.orderItem = item
			console.log(item);
		},
		copy() {
			//参数是复制的内容
			//.then()中的函数是复制成功和复制失败的两个回调函数
			this.$copyText(`${this.linkList.title} | ${this.linkList.medium_title} | https://www.qixuantong.com/${this.linkList.order_url}`).then(
				(e) => {
					return this.$message({
						message: '复制成功',
						type: 'success',
					})
				},
				(err) => {
					return this.$message({
						message: '复制失败',
						type: 'warning',
					})
				}
			)
		},
		// 下载链接
		download(item) {
			window.open(`${item.order_url}`, '_blank')
		},
		// 提交
		orderUp() {
			if (this.work != '') {
				this.curlPost('/api/users/work/add', {
					order_id: this.orderItem.id,
					desc: this.work,
				}).then((res) => {
					console.log(res)
					if (res.data.code) {
						this.getList()
						this.work = ''
						this.order = false
						this.$message({
							message: '提交成功',
							type: 'success',
						})
						return this.$router.push('/user/MyOrder')
					} else {
						return this.$message({
							message: '提交失败',
							type: 'warning',
						})
					}
				})
			}
		},
		lookShow(item) {
			const { href } = this.$router.resolve({
				name: 'lookdetails',
				query: {
					id: item
				}
			})
			window.open(href, '_blank',)
		},
		// 申请退款
		orderStatus(item, status) {
			this.rejectItem = JSON.parse(JSON.stringify(item))
			this.order_status = status
			this.rejectShow = true
			if (status == 3) {
				this.popupObj.himt = '提示：此稿件确定要申请退款吗?'
				this.popupObj.title = '申请退款'
				this.popupObj.placeholder = '请输入退款原因'
				this.reason_title = '退款原因'
			} else {
				this.popupObj.himt = '提示：此稿件确定要撤稿吗?'
				this.popupObj.title = '撤稿'
				this.popupObj.placeholder = '请输入撤稿原因'
				this.reason_title = '撤稿原因'
			}
		},
		// 撤稿
		withdraw(item) {
			this.$confirm('是否申请撤稿?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/order/edit', {
						id: item.id,
						status: 5,
						url: item.order_url,
					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.getList()
						}
					})
					this.$message({
						type: 'success',
						message: '申请撤稿成功',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 继续发布
		issue(item) {
			this.$confirm('是否继续发布?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/order/edit', {
						id: item.id,
						status: 0,
						url: item.order_url,
					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.getList()
						}
					})
					this.$message({
						type: 'success',
						message: '待发布 ',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 编辑稿件
		goEdit(item) {
			this.$store.commit('childTab', '套餐发布')
			this.$router.push({
				path: '/user/EditFill?id=' + item.id,
			})
			// this.$router.push({
			// 	path: '/user/EditFill',
			// 	query: {
			// 		id: item.id,
			// 	},
			// })
		},
		// 取消退款
		Over(item) {
			console.log(item)
			this.$confirm('是否取消退款?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/order/edit', {
						id: item.id,
						status: 1,
						url: item.order_url,
					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.$message({
								type: 'success',
								message: '待发布 ',
							})
							this.getList()
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 再发布
		redispatchFn(item) {
			console.log(item)
			this.$store.commit('childTab', '套餐发布')
			this.$router.push({
				path: '/user/SetMenu/fill?id=8',

			})
		},
		getList() {
			this.fullscreenLoading = true
			let data = {}
			data.page = this.page
			data.limit = 20
			if (this.packOrder.order_status.id !== '') {
				data.order_status = this.packOrder.order_status.id
			}
			if (this.orderId !== '') {
				data.title = this.orderId
			}
			if (this.time) {
				data.start_time = this.time[0] / 1000;
				data.end_time = this.time[1] / 1000;
			}
			data.type = 'package'
			this.curlGet('/api/users/order/list', data).then((res) => {
				if (res.data.code) {
					this.total_page = res.data.data.total_page
					this.tableData = res.data.data.list
					console.log(res.data,'data');
					if (data.order_status == 2) {
						this.total_order_price = res.data.data.total_order_price
						this.count = 0
					} else {
						this.count = res.data.data.count
						this.total_order_price = 0
					}
					this.fullscreenLoading = false
				}
			})

		},
		// 切换页
		currentTab(val) {
			console.log('切换')
			this.page = val
			this.ListFn()
		},
		// 上一页
		prevClick() {
			console.log('up')
			this.page -= 1
			this.ListFn()
		},
		// 下一页
		// next-click
		nextClick() {
			console.log('down')
			this.page += 1
			this.ListFn()
		},
		// 选中日期后的回调
		timerFn() {
			console.log(this.time)
			this.getList()
		},
		// 切换页
		currentTab(val) {
			console.log('切换')
			this.page = val
			this.ListFn()
		},
		// 上一页
		prevClick() {
			console.log('up')
			this.page -= 1
			this.ListFn()
		},
		// 下一页
		// next-click
		nextClick() {
			console.log('down')
			this.page += 1
			this.ListFn()
		},

	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';
</style>